import type { JSX } from 'react';

import { Translate } from '@change-corgi/core/react/i18n';
import { Button } from '@change-corgi/design-system/components/actions';
import { iconLock } from '@change-corgi/design-system/icons';
import { Flex } from '@change-corgi/design-system/layout';

import { isLoaded } from 'src/app/shared/utils/async';

import { useDonationLanguage } from '../../hooks/useDonationLanguage';

type Props = {
	disabled: boolean;
	loading: boolean;
	onClick: () => void;
};

export function SubmitButton({ disabled, loading, onClick }: Props): JSX.Element {
	const donationLanguage = useDonationLanguage();

	const getSuffix = () => {
		return isLoaded(donationLanguage) && donationLanguage.values.enabled ? '_donation' : '';
	};

	return (
		<Flex sx={{ justifyContent: 'center' }} mb={16}>
			<Button
				aria-label="submit-button"
				data-qa="submit-payment-button"
				data-testid="dlocal-button"
				disabled={disabled}
				loading={loading}
				onClick={onClick}
				mode="icon+label"
				icon={iconLock}
				sx={{ width: ['100%', '50%'] }}
			>
				<Translate value={`corgi.co.promote.submit_button_label${getSuffix()}`} />
			</Button>
		</Flex>
	);
}
