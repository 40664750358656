/* eslint-disable complexity */
/* eslint-disable max-lines-per-function */

import React from 'react';
import type { PropsWithChildren } from 'react';

// eslint-disable-next-line no-restricted-imports
import { useNavigate } from 'react-router';

import { Translate, useI18n } from '@change-corgi/core/react/i18n';
import { FakeLink } from '@change-corgi/design-system/components/actions';
import { Separator } from '@change-corgi/design-system/components/content';
import { Icon } from '@change-corgi/design-system/components/icon';
import { iconInfo, iconInfoOutline } from '@change-corgi/design-system/icons';
import { Box, Flex } from '@change-corgi/design-system/layout';
import { Heading, Text } from '@change-corgi/design-system/typography';

import { usePaypal } from 'src/app/shared/hooks/payments';
import { useLoginState } from 'src/app/shared/hooks/session';
import { isPaypalSdkLoadError } from 'src/app/shared/utils/payments';

import { usePaymentMethodSelector } from '../../hooks/usePaymentMethodSelector';
import type { SavedPaymentMethod } from '../../types';
import type { NewPaymentMethodButtonProps } from '../NewPaymentMethodButton';
import type { NewPaymentMethodPaypalProps } from '../NewPaymentMethodPaypal';
import { NewPaymentMethodPaypal } from '../NewPaymentMethodPaypal';
import type { NewPaymentMethodSepaProps } from '../NewPaymentMethodSepa';
import type { NewPaymentMethodStripeProps } from '../NewPaymentMethodStripe';
import { NewPaymentMethodStripe } from '../NewPaymentMethodStripe';
import { NewPaymentMethodVenmo } from '../NewPaymentMethodVenmo';
import type { NewStripePaymentRequestButtonProps } from '../NewStripePaymentRequestButton';
import { NewStripePaymentRequestButton } from '../NewStripePaymentRequestButton';
import { PaymentTypeButton } from '../PaymentTypeButton';
import type { SavedPaymentMethodButtonProps } from '../SavedPaymentMethodButton';
import { SavedPaymentMethodButton } from '../SavedPaymentMethodButton';

export type PaymentTerm = 'one-time' | 'monthly' | 'yearly';
type PaymentMethodSelectorProps = NewPaymentMethodButtonProps &
	NewPaymentMethodPaypalProps &
	NewPaymentMethodSepaProps &
	NewPaymentMethodStripeProps &
	NewStripePaymentRequestButtonProps & { variant: string } & Omit<SavedPaymentMethodButtonProps, 'paymentMethod'> & {
		savedPaymentMethods: SavedPaymentMethod[] | undefined;
		paymentTerm?: PaymentTerm;
	};

export function WalletPaymentMethodSelector({
	savedPaymentMethods,
	selectedPaymentType,
	usingSavedPaymentMethod,
	availableNewPaymentMethods,
	validate,
	beforeToken,
	onTokenError,
	onTokenInvalid,
	withToken,
	prePaymentChecks,
	currencyCode,
	email,
	name,
	paymentMethodSaveOptions,
	ask,
	amount,
	clearLoading,
	loading,
	validationResult,
	useDonationLanguage,
	onSelectSavedPaymentMethod,
	onSelectNewPaymentMethod,
	submitButton,
	phoneNumberInput,
	variant,
	children,
	paymentTerm,
}: PropsWithChildren<PaymentMethodSelectorProps>): React.JSX.Element {
	const {
		data: {
			showNewPaypalPaymentMethod,
			showNewStripePaymentMethod,
			showSavedPaymentMethodButtons,
			showNewStripePaymentRequestButton,
			showNewVenmoPaymentMethod,
		},
	} = usePaymentMethodSelector({
		savedPaymentMethods,
		selectedPaymentType,
		usingSavedPaymentMethod,
		availableNewPaymentMethods,
	});

	const paypalState = usePaypal();
	const isPaypalError = isPaypalSdkLoadError({ paypalState, availableNewPaymentMethods });
	const navigate = useNavigate();
	const loginState = useLoginState();
	const { localizeCurrency } = useI18n();

	return (
		<>
			<Heading my={8} as="h2" size="h5">
				<Translate value="fe.components.credit_card.payment_method" /> {/* TODO: move key to corgi namespace */}
			</Heading>
			<Box marginBottom={8}>
				<Flex sx={{ gap: '5px', marginBottom: 8, flexDirection: variant === 'variant_1' ? 'column' : 'row' }}>
					{availableNewPaymentMethods.map((paymentType) => {
						if (paymentType !== 'creditCard')
							return (
								<Flex sx={{ flex: 1 }} key={paymentType}>
									<PaymentTypeButton
										type={paymentType}
										selectedPaymentType={selectedPaymentType}
										onSelectNewPaymentMethod={onSelectNewPaymentMethod}
										disabled={paymentType === 'paypal' && isPaypalError}
										usingSavedPaymentMethod={usingSavedPaymentMethod}
										isVariant
										variant={variant}
										availableNewPaymentMethods={availableNewPaymentMethods}
									/>
								</Flex>
							);
						return null;
					})}
				</Flex>
				{isPaypalError && (
					<Flex
						my={16}
						sx={{
							fontSize: [10, 12],
							justifyContent: 'center',
							lineHeight: '18px',
							flexDirection: 'row',
						}}
					>
						<Icon icon={iconInfo} size={16} mr={8} />
						<Translate value="corgi.payments.paypal_sdk_not_loaded" />
						&nbsp;
						<FakeLink
							data-qa="paypal-error-retry-link"
							sx={{ display: 'contents', color: 'secondary-blue', fontWeight: 'normal', fontSize: 'caption' }}
							onClick={async () => navigate(0)}
						>
							<Translate value="corgi.payments.click_to_try_again" />
						</FakeLink>
					</Flex>
				)}
			</Box>
			<Box>
				{showSavedPaymentMethodButtons &&
					savedPaymentMethods?.map((paymentMethod) => (
						<SavedPaymentMethodButton
							key={paymentMethod.type}
							paymentMethod={paymentMethod}
							selectedPaymentType={selectedPaymentType}
							usingSavedPaymentMethod={usingSavedPaymentMethod}
							onSelectSavedPaymentMethod={onSelectSavedPaymentMethod}
							isVariant
						/>
					))}
				<Flex sx={{ gap: '10px', marginBottom: 20 }}>
					{availableNewPaymentMethods.map((paymentType) => {
						if (paymentType === 'creditCard')
							return (
								<Flex sx={{ flex: 1 }} key={paymentType}>
									<PaymentTypeButton
										type={paymentType}
										selectedPaymentType={selectedPaymentType}
										onSelectNewPaymentMethod={onSelectNewPaymentMethod}
										disabled={false}
										usingSavedPaymentMethod={usingSavedPaymentMethod}
										isVariant
									/>
								</Flex>
							);
						return null;
					})}
				</Flex>
				<Box paddingTop={8}>
					<Separator label="Complete your payment" />
				</Box>

				<Box
					sx={{
						backgroundColor:
							usingSavedPaymentMethod || (loginState === 'AUTHENTICATED' && selectedPaymentType !== 'creditCard')
								? 'undefined'
								: '#F2F2F2',
						paddingX: 16,
						paddingY: 8,
						marginTop: 8,
						borderRadius: 'standard',
					}}
				>
					{children}
					{!showNewStripePaymentMethod && <Box>{phoneNumberInput}</Box>}
					<Box
						mx="auto"
						sx={{ width: ['100%', '58%'], display: showNewPaypalPaymentMethod ? 'block' : 'none', marginTop: 16 }}
						data-testid="paypal-button" // TODO: replace data-testid with data-qa when the regression suite is updated
					>
						<NewPaymentMethodPaypal
							currencyCode={currencyCode}
							amount={amount}
							loading={loading}
							email={email}
							paymentMethodSaveOptions={paymentMethodSaveOptions}
							useDonationLanguage={useDonationLanguage}
							validationResult={validationResult}
							validate={validate}
							beforeToken={beforeToken}
							onTokenError={onTokenError}
							onTokenInvalid={onTokenInvalid}
							withToken={withToken}
							prePaymentChecks={prePaymentChecks}
						/>
					</Box>
					<Box mx="auto" sx={{ display: showNewVenmoPaymentMethod ? 'block' : 'none' }} data-qa="venmo-button-wrapper">
						<NewPaymentMethodVenmo
							loading={loading}
							email={email}
							paymentMethodSaveOptions={paymentMethodSaveOptions}
							validationResult={validationResult}
							validate={validate}
							beforeToken={beforeToken}
							onTokenError={onTokenError}
							onTokenInvalid={onTokenInvalid}
							withToken={withToken}
							prePaymentChecks={prePaymentChecks}
						/>
					</Box>
					<Box
						mx="auto"
						sx={{ width: ['100%', '58%'], display: showNewStripePaymentRequestButton ? 'block' : 'none' }}
						data-testid="apple-pay-button" // TODO: replace data-testid with data-qa when the regression suite is updated
					>
						<NewStripePaymentRequestButton
							currencyCode={currencyCode}
							ask={ask}
							amount={amount}
							selectedPaymentType={selectedPaymentType}
							clearLoading={clearLoading}
							beforeToken={beforeToken}
							withToken={withToken}
							validate={validate}
							prePaymentChecks={prePaymentChecks}
						/>
					</Box>
					{(paymentTerm === 'monthly' || paymentTerm === 'yearly') && (
						<Box
							mx="auto"
							sx={{
								display: selectedPaymentType === 'creditCard' || usingSavedPaymentMethod ? 'none' : 'block',
								textAlign: 'center',
							}}
							data-testid="chipping-in-info"
						>
							<Text size="small">
								<span sx={{ verticalAlign: 'middle' }}>
									<Icon icon={iconInfoOutline}></Icon>
								</span>{' '}
								Chipping in {localizeCurrency(amount, currencyCode)} {paymentTerm}
							</Text>
						</Box>
					)}
					<Box
						sx={{
							display: showNewStripePaymentMethod ? 'block' : 'none',
						}}
					>
						<NewPaymentMethodStripe
							email={email}
							name={name}
							paymentMethodSaveOptions={paymentMethodSaveOptions}
							beforeToken={beforeToken}
							onTokenError={onTokenError}
							onTokenInvalid={onTokenInvalid}
							withToken={withToken}
							validate={validate}
							prePaymentChecks={prePaymentChecks}
							submitButton={submitButton}
							phoneNumberInput={phoneNumberInput}
						/>
					</Box>
				</Box>
			</Box>
		</>
	);
}
