import type { UtilityContext } from '@change-corgi/core/react/utilityContext';

import { setupPaymentMethod } from 'src/app/shared/api/payments';

export type PaymentType = 'PAYPAL' | 'VENMO';

// eslint-disable-next-line max-params
export async function setupBraintreePaymentMethod(
	token: string,
	usage: 'OFF_SESSION' | 'ON_SESSION',
	utilityContext: UtilityContext,
	paymentType: PaymentType,
	countryCode?: string | null,
	currency?: string | null,
): Promise<string> {
	const setupPaymentMethodResp = await setupPaymentMethod({
		input: {
			gateway: 'BRAINTREE',
			usage,
			token,
			customer: {
				countryCode: countryCode ?? null,
				currencyCode: currency ?? null,
				billingName: null,
			},
			type: paymentType,
		},
		utilityContext,
	});

	if (!setupPaymentMethodResp.gatewayData) throw new Error('Error retrieving  setup intent');

	// TODO: add braintree schema definitions
	// https://change.atlassian.net/browse/CHANGE-71127
	// We are using the Stripe schema definitions for this experiment
	// When or if this is productized, we will add the respective schema definitions
	if (setupPaymentMethodResp.gatewayData.__typename !== 'SetupPaymentMethodStripeGatewayData')
		throw new Error('Error retrieving setup intent');

	const stripeGatewayData = setupPaymentMethodResp.gatewayData;

	if (!stripeGatewayData.setupIntent) throw new Error('Error retrieving  setup intent');

	return stripeGatewayData.setupIntent.clientSecret;
}
