import type { PaymentType } from './index';

type PaymentMethodsEnabledFor = {
	payment_method_name: string;
	enabled: boolean;
	safe_listed_user_identifiers: readonly string[] | undefined;
	block_listed_user_identifiers: readonly string[] | undefined;
};

function isPaymentMethodEnabledForUser({
	userId,
	paymentType,
}: {
	userId?: string;
	paymentType: PaymentMethodsEnabledFor;
}): boolean {
	const isUserInBlockList = userId ? paymentType.block_listed_user_identifiers?.includes(userId) : false;
	const isUserInSafeList = userId ? paymentType.safe_listed_user_identifiers?.includes(userId) : false;
	return !!(!isUserInBlockList && (paymentType.enabled || isUserInSafeList));
}

export function availablePaymentMethods({
	canUsePaymentRequestButtonMethods,
	isMobileUserAgent,
	paymentMethodsEnabledFor,
	paypalMobileDefault,
	userId,
	canUseVenmo,
}: {
	canUsePaymentRequestButtonMethods: {
		applePay: boolean;
		googlePay: boolean;
	};
	isMobileUserAgent: boolean;
	paymentMethodsEnabledFor: readonly PaymentMethodsEnabledFor[] | undefined;
	paypalMobileDefault: boolean;
	userId: string | undefined;
	canUseVenmo?: boolean;
}): PaymentType[] | [] {
	const availableNewPaymentMethods: PaymentType[] = [];
	paymentMethodsEnabledFor?.forEach((paymentType) => {
		if (isPaymentMethodEnabledForUser({ userId, paymentType })) {
			switch (paymentType.payment_method_name) {
				case 'paypal':
					availableNewPaymentMethods[isMobileUserAgent && paypalMobileDefault ? 'unshift' : 'push']('paypal');
					break;
				case 'applePay':
				case 'googlePay':
					if (canUsePaymentRequestButtonMethods[paymentType.payment_method_name])
						availableNewPaymentMethods.push(paymentType.payment_method_name as PaymentType);
					break;
				default:
					availableNewPaymentMethods.push(paymentType.payment_method_name as PaymentType);
			}
		}
	});
	// this is for experiment. After venmo is productized and added to payment_methods_enabled_for fc
	// we can remove this part and add venmo to the above switch case
	if (canUseVenmo) {
		if (availableNewPaymentMethods.length > 2) {
			availableNewPaymentMethods.splice(2, 0, 'venmo');
		} else {
			availableNewPaymentMethods.push('venmo');
		}
	}

	return availableNewPaymentMethods.slice(0, 3);
}
