import React from 'react';

import type { PayPalButtons, PayPalButtonsComponentProps } from '@paypal/react-paypal-js';
import ReactDOM from 'react-dom';

import { getWindow } from '@change-corgi/core/window';
import { Box } from '@change-corgi/design-system/layout';

import { PaypalButtonLoading } from '../PaypalButtonLoading';

type Props = PayPalButtonsComponentProps & {
	loading?: boolean;
};

export function PaypalButtonSDK({ loading, ...props }: Props): React.JSX.Element | null {
	const window = getWindow();

	if (!window.paypal) return null;

	// TODO: paypal's type defs don't include the driver function for some reason.
	// Remove these overrides if/when they ever do
	// eslint-disable-next-line @typescript-eslint/ban-ts-comment
	// @ts-ignore
	// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-call
	const PaypalDriverButton: typeof PayPalButtons = window.paypal.Buttons.driver('react', { React, ReactDOM });

	// Box with negative margin here to remove extra padding added by paypal sdk
	return (
		<Box mb="-5px">
			{loading && <PaypalButtonLoading style={props.style} />}
			<div sx={{ display: loading ? 'none' : 'unset' }} data-testid="paypal-driver-button-wrapper">
				<PaypalDriverButton {...props} />
			</div>
		</Box>
	);
}
